<template>
  <div>
    <!-- Header Component (Already responsive) -->
    <Header />

    <!-- Dashboard Container -->
    <div class="container-fluid mt-4 px-4">
      
      <!-- Dashboard Title -->
      <div class="text-center mb-5">
        <h1 class="display-4">Bitcoin Price Prediction and Simulated Investment Dashboard</h1>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <!-- Real-time and Predicted Prices Chart -->
        <div class="col-12 mb-5">
          <div class="card shadow-sm h-100">
            <div class="card-header bg-primary text-white">
              <h5 class="mb-0">Bitcoin Price and Prediction (Last 8 Hours)</h5>
            </div>
            <div class="card-body d-flex flex-column">
              <div ref="chart" class="flex-grow-1 chart-container"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Investment Results Section -->
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card shadow-sm h-100">
            <div class="card-header bg-success text-white">
              <h5 class="mb-0">Simulated Investment Results</h5>
            </div>
            <div class="card-body">
              <div class="row g-3">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="info-box p-3 border rounded">
                    <h6 class="mb-2">Balance</h6>
                    <p class="mb-0">${{ balance.toFixed(2) }}</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="info-box p-3 border rounded">
                    <h6 class="mb-2">BTC Holdings</h6>
                    <p class="mb-0">{{ btcBalance.toFixed(6) }} BTC</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="info-box p-3 border rounded">
                    <h6 class="mb-2">Current Total Assets</h6>
                    <p class="mb-0">${{ currentTotal.toFixed(2) }}</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="info-box p-3 border rounded">
                    <h6 class="mb-2">Profit/Loss</h6>
                    <p class="mb-0">${{ profitLoss.toFixed(2) }} ({{ profitLossRate.toFixed(2) }}%)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Transaction History Section -->
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card shadow-sm h-100">
            <div class="card-header bg-warning text-white">
              <h5 class="mb-0">Transaction History</h5>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th scope="col">Time</th>
                      <th scope="col">Type</th>
                      <th scope="col">Price (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(position, index) in positions" :key="index">
                      <td>{{ new Date(position.Time).toLocaleString() }}</td>
                      <td>
                        <span
                          :class="{
                            'badge bg-success': position.Type === 'Buy',
                            'badge bg-danger': position.Type === 'Sell',
                          }"
                        >
                          {{ position.Type }}
                        </span>
                      </td>
                      <td>${{ position.Price.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Yearly Bitcoin Prices Chart -->
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card shadow-sm h-100">
            <div class="card-header bg-secondary text-white">
              <h5 class="mb-0">Bitcoin Prices Over the Last Year</h5>
            </div>
            <div class="card-body d-flex flex-column">
              <div ref="yearlyChart" class="flex-grow-1 chart-container"></div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'; // Import Header component
import Plotly from 'plotly.js-dist';

export default {
  name: 'BtcInvestAI',
  components: {
    Header, // Use Header component
  },
  data() {
    return {
      actualTimes: [],
      actualPrices: [],
      predictedTimes: [],
      predictedPrices: [],
      positions: [],
      balance: 0,
      btcBalance: 0,
      currentTotal: 0,
      profitLoss: 0,
      profitLossRate: 0,
      yearlyTimes: [],
      yearlyPrices: [],
      intervalId: null,
    };
  },
  mounted() {
    this.fetchData();
    this.intervalId = setInterval(this.fetchData, 10000); // Update data every 10 seconds
  },
  beforeUnmount() { // Use beforeUnmount in Vue 3
    clearInterval(this.intervalId);
  },
  methods: {
    fetchData() {
      fetch('https://btcpredictaibackend.onrender.com/api/data') // 수정된 API 엔드포인트
        .then(response => response.json())
        .then(data => {
          console.log('Received data:', data); // 데이터 로그 출력
          this.actualTimes = data.actual_times.map(t => new Date(t));
          this.actualPrices = data.actual_prices;
          this.predictedTimes = data.predicted_times.map(t => new Date(t));
          this.predictedPrices = data.predicted_prices;
          this.positions = data.positions;
          this.balance = data.balance;
          this.btcBalance = data.btc_balance;
          this.currentTotal = data.current_total;
          this.profitLoss = data.profit_loss;
          this.profitLossRate = data.profit_loss_rate;
          this.yearlyTimes = data.yearly_times.map(t => new Date(t));
          this.yearlyPrices = data.yearly_prices;
          this.updateChart();
          this.updateYearlyChart();
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Optionally, display an alert or notification to the user
        });
    },
    updateChart() {
      console.log('Updating main chart');
      const actualTrace = {
        x: this.actualTimes,
        y: this.actualPrices,
        mode: 'lines',
        name: 'Actual Price',
        line: { color: '#007bff' }, // Bootstrap primary color
      };

      const predictedTrace = {
        x: this.predictedTimes,
        y: this.predictedPrices,
        mode: 'markers+lines',
        name: 'Predicted Price',
        line: { color: '#fd7e14' }, // Bootstrap orange color
        marker: { size: 6 },
      };

      const buyPositions = this.positions.filter(p => p.Type === 'Buy');
      const sellPositions = this.positions.filter(p => p.Type === 'Sell');

      const buyTrace = {
        x: buyPositions.map(p => new Date(p.Time)),
        y: buyPositions.map(p => p.Price),
        mode: 'markers+text',
        name: 'Buy',
        text: buyPositions.map(() => 'Buy'),
        textposition: 'top center',
        marker: { symbol: 'triangle-up', color: '#28a745', size: 12 }, // Bootstrap success color
      };

      const sellTrace = {
        x: sellPositions.map(p => new Date(p.Time)),
        y: sellPositions.map(p => p.Price),
        mode: 'markers+text',
        name: 'Sell',
        text: sellPositions.map(() => 'Sell'),
        textposition: 'bottom center',
        marker: { symbol: 'triangle-down', color: '#dc3545', size: 12 }, // Bootstrap danger color
      };

      const layout = {
        title: '',
        xaxis: {
          title: 'Time',
          type: 'date',
          range: [
            new Date(new Date().getTime() - 8 * 60 * 60 * 1000), // 8 hours ago
            new Date(),
          ],
          tickformat: '%H:%M',
          dtick: 60000 * 10, // 10분 간격으로 x축 눈금 표시
        },
        yaxis: {
          title: 'Price (USD)',
        },
        showlegend: true,
        autosize: true,
        margin: { t: 40, l: 50, r: 30, b: 50 },
      };

      Plotly.react(this.$refs.chart, [actualTrace, predictedTrace, buyTrace, sellTrace], layout, { responsive: true });
    },
    updateYearlyChart() {
      console.log('Updating yearly chart');
      const yearlyTrace = {
        x: this.yearlyTimes,
        y: this.yearlyPrices,
        mode: 'lines',
        name: 'Bitcoin Price',
        line: { color: '#6f42c1' }, // Bootstrap purple color
      };

      const layout = {
        title: '',
        xaxis: {
          title: 'Date',
          tickformat: '%Y-%m-%d',
          type: 'date',
          dtick: 'M1', // 월 단위로 x축 눈금 표시
        },
        yaxis: {
          title: 'Price (USD)',
        },
        showlegend: true,
        autosize: true,
        margin: { t: 40, l: 50, r: 30, b: 50 },
      };

      Plotly.react(this.$refs.yearlyChart, [yearlyTrace], layout, { responsive: true });
    },
  },
};
</script>

<style scoped>
/* Custom Styles */

/* Chart Container */
.chart-container {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }
}

@media (max-width: 576px) {
  .chart-container {
    height: 250px;
  }
}

/* Info Boxes */
.info-box {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-box h6 {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.info-box p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #343a40;
  margin: 0;
}

/* Table Styles */
.table thead th {
  vertical-align: middle;
  text-align: center;
}

.table tbody td {
  vertical-align: middle;
  text-align: center;
}

/* Badge Styles */
.badge {
  font-size: 0.9rem;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .info-box h6 {
    font-size: 0.8rem;
  }

  .info-box p {
    font-size: 1rem;
  }
}
</style>
