<template>
  <div>
    <Header />
    <div class="content">
      <h1>Game Development Projects</h1>
      <p>This section contains various game development projects.</p>

      <div
        v-for="(project, index) in gameProjects"
        :key="index"
        class="project-card"
      >
        <div 
          :class="['media-list-container', { 'scroll-enabled': project.media.length > 1 }]"
        >
          <div
            v-for="(media, mediaIndex) in project.media"
            :key="mediaIndex"
            class="media-container"
          >
            <template v-if="media.type === 'video'">
              <iframe
                :src="media.src"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="media"
              ></iframe>
            </template>
            <template v-else-if="media.type === 'image'">
              <img :src="media.src" alt="Project Image" class="media" />
            </template>
          </div>
        </div>
        <div class="project-info">
          <h3>{{ project.title }}</h3>
          <p>{{ project.description }}</p>
          <p><strong>Date:</strong> {{ project.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      gameProjects: [
        {
          title: "A First Step as a Game Developer",
          description: "The right one is a my first game I made with Unity3D when I was an elementary student.",
          media: [
            { type: "video", src: "https://www.youtube.com/embed/X785uGwQbdc" },
            { type: "image", src: require('@/assets/GameDevelopment/Pic1.jpg') },
            { type: "video", src: "https://www.youtube.com/embed/MsKsO8jN90s" },
          ],
          date: "November21-2015 & October28-2017",
        },
        {
          title: "Various attempts...",
          description: "Games I made",
          media: [
            { type: "video", src: "https://www.youtube.com/embed/B3Jm9GWJ0ks" }, 
            { type: "video", src: "https://www.youtube.com/embed/Tx1J0Q8o90s" },
            { type: "video", src: "https://www.youtube.com/embed/do04dv7g_EE?si=3AToTPcvPAnw8kuR" },
            { type: "video", src: "https://www.youtube.com/embed/5KrAkeU_Ycg" },
          ],
          date: "November18-2017 & December7-2017 & August23-2022",
        },
        {
          title: "My First Published Game",
          description: "This game is what I published first time on Goolge App Store. It's horror game.",
          media: [
            { type: "image", src: require('@/assets/GameDevelopment/Pic2.jpg') },
          ],
          date: "January23-2018",
        },
        {
          title: "The Line",
          description: "This is 2D adventure game.",
          media: [
            { type: "video", src: "https://www.youtube.com/embed/PKf1LvinP88" }, 
            { type: "video", src: "https://www.youtube.com/embed/0XEnP2dlqxE" },
          ],
          date: "February27-2021",
        },
        {
          title: "My First Online FPS Game",
          description: "Online FPS Game using Photon Server.(Designed with Blender3D)",
          media: [
            { type: "video", src: "https://www.youtube.com/embed/b_ERXsuVcGQ" },
          ],
          date: "June14-2021",
        },
        {
          title: "Surveillance1",
          description: "Side-scrolling 3D Escape Puzzle Game",
          media: [
            { type: "video", src: "https://www.youtube.com/embed/1p5y8vRE1XM?si=ljyZz1pG-bq4BIm6" },
          ],
          date: "November16-2021",
        },
        {
          title: "Global Game Jam 2022",
          description: "I participated as a programmer and made Pickaboom with teammates. https://v3.globalgamejam.org/2022/games/pickaboom-6",
          media: [
            { type: "image", src: require('@/assets/GameDevelopment/Pic14.jpg') },
            { type: "video", src: "https://www.youtube.com/embed/CAvT_1oC-Gs" },
            { type: "video", src: "https://www.youtube.com/embed/y6QdCSz3GzY" },
          ],
          date: "January20-2022",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
  padding: 2rem;
  text-align: center;
}

.project-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
}

/* 기본적으로 미디어 리스트를 중앙 정렬 */
.media-list-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* 스크롤이 필요한 경우(요소가 2개 이상인 경우)에만 스크롤 활성화 */
.scroll-enabled {
  justify-content: flex-start;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

/* 미디어 컨테이너의 크기와 비율을 고정 */
.media-container {
  flex: 0 0 auto;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16 / 9;
  height: auto;
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.project-info {
  text-align: center;
}

.project-info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project-info p {
  font-size: 1rem;
  color: #666;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .media-list-container {
    gap: 0.5rem;
  }

  .media-container {
    width: 80%;
    max-width: none;
    aspect-ratio: 16 / 9;
    height: auto;
  }
}
</style>
