<template>
  <div>
    <Header />
    <div class="content">
      <h1>Development Projects</h1>
      <p>Here you can find a variety of projects showcasing my development experience.</p>

      <div
        v-for="(project, index) in developmentProjects"
        :key="index"
        class="project-card"
      >
        <div 
          :class="['media-list-container', { 'scroll-enabled': project.media.length > 1 }]"
        >
          <div
            v-for="(media, mediaIndex) in project.media"
            :key="mediaIndex"
            class="media-container"
          >
            <template v-if="media.type === 'video'">
              <iframe
                :src="media.src"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="media"
              ></iframe>
            </template>
            <template v-else-if="media.type === 'image'">
              <img :src="media.src" alt="Project Image" class="media" />
            </template>
          </div>
        </div>
        <div class="project-info">
          <h3>{{ project.title }}</h3>
          <p>{{ project.description }}</p>
          <p><strong>Date:</strong> {{ project.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'; // Header 경로 확인

export default {
  components: {
    Header,
  },
  data() {
    return {
      developmentProjects: [
        {
          title: "Korean Youth Entrepreneur Competition 2022 - 2nd Place",
          description: "Me and my friend got 2nd place with AR education app for helping understanding by showing 3D objects.",
          media: [
            { type: "image", src: require('@/assets/GameDevelopment/Pic4.jpg') },
            { type: "video", src: "https://www.youtube.com/embed/UvF5uPDVfU0" },
          ],
          date: "December 22, 2022",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
  padding: 2rem;
  text-align: center;
}

.project-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
}

/* 기본적으로 미디어 리스트를 중앙 정렬 */
.media-list-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* 스크롤이 필요한 경우(요소가 2개 이상인 경우)에만 스크롤 활성화 */
.scroll-enabled {
  justify-content: flex-start;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

/* 미디어 컨테이너의 크기와 비율을 고정 */
.media-container {
  flex: 0 0 auto;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16 / 9;
  height: auto;
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.project-info {
  text-align: center;
}

.project-info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project-info p {
  font-size: 1rem;
  color: #666;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .media-list-container {
    gap: 0.5rem;
  }

  .media-container {
    width: 80%;
    max-width: none;
    aspect-ratio: 16 / 9;
    height: auto;
  }
}
</style>