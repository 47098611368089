<template>
  <div id="projects">
    <div class="projects-section">
      <h2 class="section-title">My Projects</h2>
      <div class="projects-container">

        <!-- Game Development Project -->
        <div class="project-card">
          <div class="image-container">
            <img src="@/assets/game-development.jpg" alt="Game Development Project" class="project-image">
          </div>
          <div class="project-content">
            <h3 class="project-title">Game Development Project</h3>
            <p class="project-description">
              Explore various game development projects using Unity Engine, showcasing different gameplay mechanics and 3D design techniques.
            </p>
            <router-link to="/game-development" class="project-link">View More</router-link>
          </div>
        </div>

        <!-- AI/SW Development Project -->
        <div class="project-card">
          <div class="image-container">
            <img src="@/assets/development.jpg" alt="Development Project" class="project-image">
          </div>
          <div class="project-content">
            <h3 class="project-title">AI/SW Development</h3>
            <p class="project-description">
              Explore various AI/Software development projects.
            </p>
            <router-link to="/development" class="project-link">View More</router-link>
          </div>
        </div>

        <!-- Electrical Engineering Project -->
        <div class="project-card">
          <div class="image-container">
            <img src="@/assets/Electrical_Engineering.jpg" alt="Electrical Engineering Project" class="project-image">
          </div>
          <div class="project-content">
            <h3 class="project-title">Electrical Engineering Project</h3>
            <p class="project-description">
              Explore projects related to Electrical Engineering, including circuit design, signal processing, and applications of modern technology in engineering.
            </p>
            <router-link to="/electrical-engineering" class="project-link">View More</router-link>
          </div>
        </div>

        <!-- Realtime BTC Invest AI Project -->
        <div class="project-card">
          <div class="image-container">
            <img src="@/assets/btc-invest-ai.jpg" alt="Realtime BTC Invest AI" class="project-image">
          </div>
          <div class="project-content">
            <h3 class="project-title">Realtime BTC Invest AI</h3>
            <p class="project-description">
              An AI-powered platform for real-time Bitcoin price analysis and investment strategies. Built with advanced machine learning models to provide accurate predictions and actionable insights.
            </p>
            <router-link to="/btc-invest-ai" class="project-link">View More</router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects',
};
</script>

<style scoped>
.projects-section {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 90%;
}

.project-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-width: 400px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
}

.image-container {
  width: 100%;
  padding-top: 100%; /* 정사각형 비율을 유지하기 위해 너비와 같은 값을 사용 */
  position: relative;
  overflow: hidden;
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 잘리더라도 정사각형 안에 맞게 표시 */
}
  
.project-content {
  padding: 1rem;
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.project-link {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
}

.project-link:hover {
  background-color: #0056b3;
}
</style>
